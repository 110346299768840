import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { appJSON } from "../../../utils/AxiosConfig";
import axios from "axios";
import { deleteTableType } from "../../../interfaces/Interfaces";

const initialState = {};

export const fetchDeleteTable = createAsyncThunk<
  void,
  deleteTableType,
  { rejectValue: string }
>("table/deleteTable", async (data: deleteTableType, { rejectWithValue }) => {
  try {
    const URL = `${process.env.REACT_APP_DELETE_TABLES}${data.type}/delete_all`

    const response = await appJSON.delete<void>(URL);

    window.location.reload()
    return response.data;
  } catch (error: unknown) {
    if (error instanceof axios.AxiosError) {
      return rejectWithValue(error.message);
    }
    throw error;
  }
});

const deleteTable = createSlice({
  name: "tableDelete",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDeleteTable.fulfilled, (_, action) => {
      return action.payload;
    });
    builder.addCase(fetchDeleteTable.rejected, (state) => {
      return state;
    });
  },
});

export default deleteTable.reducer;
