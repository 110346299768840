import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Inter from "../../../interfaces/Interfaces";
import axios from "axios";
import { appJSON } from "../../../utils/AxiosConfig";

const initialState: Inter.getStudentsGroup[] = [
  {
    name: "",
    address: "",
    is_male: true,
    birthdate: "",
    phone: "",
    unique_id: "",
    surname: "",
  },
];

export const fetchStudentsGroup = createAsyncThunk<
  Inter.getStudentsGroup[],
  Inter.sendStudentsGroup,
  { rejectValue: string }
>("students/getStudentsGroup", async (data, { rejectWithValue }) => {
  try {
    const URL = `${process.env.REACT_APP_GET_STUDENTS_COURSE}`;
    const response = await appJSON.get<Inter.getStudentsGroup[]>(URL, {
      params: {
        group_name: data.group,
      },
    });
    return response.data;
  } catch (error: unknown) {
    if (error instanceof axios.AxiosError) {
      return rejectWithValue(error.message);
    }
    throw error;
  }
});

const getStudentGroup = createSlice({
  name: "students_group",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStudentsGroup.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(fetchStudentsGroup.rejected, (state) => {
      return state;
    });
  },
});

export default getStudentGroup.reducer;
