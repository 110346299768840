import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Inter from "../../../interfaces/Interfaces";
import axios from "axios";
import { appJSON } from "../../../utils/AxiosConfig";

const initialState = {};

export const fetchSaveStudent = createAsyncThunk<
  void,
  Inter.saveStudent,
  { rejectValue: string }
>("students/saveStudent", async (data, { rejectWithValue }) => {
  try {
    const URL = `${process.env.REACT_APP_SAVE_STUDENT}`;
    const response = await appJSON.post<void>(URL, data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof axios.AxiosError) {
      return rejectWithValue(error.message);
    }
    throw error;
  }
});

const saveStudents = createSlice({
  name: "students_save",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSaveStudent.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(fetchSaveStudent.rejected, (state) => {
      return state;
    });
  },
});

export default saveStudents.reducer;
