import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import * as Inter from "../../../interfaces/Interfaces"

interface Props {
  data: string | number;
  handleData: (e: SelectChangeEvent<string | number>) => void;
  schools: Inter.getSchoolListArray
}

const SelectSchool: React.FC<Props> = ({ data, handleData, schools }) => {
  return (
    <Box sx={{ width: "100%", marginTop: "50px" }}>
      <FormControl fullWidth>
        <InputLabel id="amount">المؤسسة</InputLabel>
        <Select
          labelId="amount"
          id="amount"
          value={data}
          label="المؤسسة"
          onChange={(e) => handleData(e)}
        >
          {schools.schools[0] !== null && (
              schools.schools.map((school) => (
                  <MenuItem value={school!.id} key={school!.id}>{school!.name}</MenuItem>
              ))
          )}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectSchool;
