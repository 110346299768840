import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {appJSON} from "../../../utils/AxiosConfig";
import axios from "axios";
import { getCredExcel } from "../../../interfaces/Interfaces";

const initialState = {};

export const fetchGetCredExcel = createAsyncThunk<BlobPart, getCredExcel, { rejectValue: string }>(
	"credentials/downloadCredentials",
	async (data: getCredExcel, {rejectWithValue}) => {
		try {
			const URL = `${process.env.REACT_APP_DOWNLOAD_EXCEL_CREDENTIALS}`;

            const params = {
                role_filter: data.role_filter,
                ...(data.name_filter && {name_filter: data.name_filter}),
                ...(data.surname_filter && {surname_filter: data.surname_filter}),
                ...(data.group_id_filter && {group_id_filter: data.group_id_filter})
            }

			const response = await appJSON.get<BlobPart>(URL, {
				responseType: "blob", params
			});

			const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = url;
			a.download = "students.xlsx";

			a.addEventListener("click", () => {
				setTimeout(() => {
					window.URL.revokeObjectURL(url);
					a.remove();
				}, 1000);
			});

			a.click();

			return response.data;
		} catch
		(error: unknown) {
			if (error instanceof axios.AxiosError) {
				return rejectWithValue(error.message);
			}
			throw error;
		}
	}
);


const downloadExcelCredentials = createSlice({
	name: "download_excel_students",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchGetCredExcel.fulfilled, (state, action) => {
			return action.payload;
		});
		builder.addCase(fetchGetCredExcel.rejected, (state) => {
			return state;
		});
	},
});

export default downloadExcelCredentials.reducer;