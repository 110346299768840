import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import getSchoolsListReducer from "./slices/get/getSchoolsList";
import authReducer from "./slices/create/authSlice";
import teacherReducer from "./slices/teacherSlice";
import homeworkReducer from "./slices/get/getHomeworkSlice";
import scheduleReducer from "./slices/get/getScheduleSlice";
import marksReducer from "./slices/get/marksSlice";
import directorsReducer from "./slices/directorsSlice";
import directorReducer from "./slices/directorSlice";
import teacherInfoReducer from "./slices/get/teachersInfoSlice";
import teachersMarksReduce from "./slices/get/teachersMarksSlice";
import createHomework from "./slices/create/createHomeworkSlice";
import loadingSlice from "./slices/loadingSlice";
import createPeriodSlice from "./slices/create/periodsCreate";
import studentsEditReduce from "./slices/studentsEdit";
import createCourseReducer from "./slices/create/createCourse";
import getCourseReducer from "./slices/get/getCourse";
import studentsCourseReducer from "./slices/get/studentsCourseSlice";
import getAllGroupsReducer from "./slices/get/getAllGroups";
import addMasterTeacherReducer from "./slices/update/addMasterTeacher";
import getAllStudentsReducer from "./slices/get/getAllStudents";
import getAllTeachersReducer from "./slices/get/GetAllTeachers";
import saveStudentReducer from "./slices/create/saveStudent";
import saveTeacherReducer from "./slices/create/saveTeacher";
import createScheduleReducer from "./slices/create/createSchedule";
import getFirstWeekReducer from "./slices/get/getFirstWeek";
import getPeriodsReducer from "./slices/get/getPeriods";
import patchStudentReducer from "./slices/update/patchStudent";
import deleteStudentReducer from "./slices/delete/deleteStudent";
import createCGTReducer from "./slices/create/createCGT";
import deleteScheduleReducer from "./slices/delete/deleteSchedule";
import deleteSubjectReducer from "./slices/delete/deleteSubject";
import createMarkReducer from "./slices/create/createMark";
import createGroupReducer from "./slices/create/createGroup";
import deleteMarkReducer from "./slices/delete/deleteMark";
import deleteGroupReducer from "./slices/delete/deleteGroup";
import getCGTReducer from "./slices/get/getCGT";
import deleteCGTReducer from "./slices/delete/deleteCGT";
import deleteTeacherReducer from "./slices/delete/deleteTeacher";
import getCredentialsReducer from "./slices/get/getCredentials";
import patchMarksReducer from "./slices/update/patchMarks";
import getTeachHomewkReducer from "./slices/get/getTeachHomework";
import patchTeachHomewkReducer from "./slices/update/patchTeachHomework";
import deletePeriodReducer from "./slices/delete/deletePeriod";
import patchCGTReducer from "./slices/update/patchCGT";
import getAssessmentReducer from "./slices/get/getAssessment";
import createAssessmentReducer from "./slices/create/createAssessment";
import patchAssessmentReducer from "./slices/update/patchAssessment";
import deleteAssessmentReducer from "./slices/delete/deleteAssessment";
import createQuarterlyGradeReducer from "./slices/create/createQuarterlyGrade";
import getQuarterlyGradeReducer from "./slices/get/getQuarterlyGrade";
import patchQuarterlyGradeReducer from "./slices/update/patchQuarterlyGrade";
import deleteQuarterlyGradeReducer from "./slices/delete/deleteQuarterlyGrade";
import createParentReducer from "./slices/create/createParent";
import getParentsReducer from "./slices/get/getParents";
import patchParentReducer from "./slices/update/patchParent";
import deleteParentReducer from "./slices/delete/deleteParent";
import createTrimAssessmentReducer from "./slices/create/createTrimAssessment";
import getTrimAssessmentReducer from "./slices/get/getTrimAssessment";
import patchTrimAssessmentReducer from "./slices/update/patchTrimAssessment";
import deleteTrimAssessmentReducer from "./slices/delete/deleteTrimAssessment";
import patchLessonReducer from "./slices/update/patchLesson";
import patchDRScheduleReducer from "./slices/update/patchDRSchedule";
import patchCourseReducer from "./slices/update/patchCourse";
import downloadExcelCredentialsReducer from "./slices/get/getCredExcel"
import deleteTableReducer from "./slices/delete/deleteTable"
import patchPeriodReducer from "./slices/update/patchPeriod"

const rootReducer = combineReducers({
  auth: authReducer,
  teacher: teacherReducer,
  homework: homeworkReducer,
  schedule: scheduleReducer,
  marks: marksReducer,
  directors: directorsReducer,
  director: directorReducer,
  createHomework: createHomework,
  loading: loadingSlice,
  teacherInfo: teacherInfoReducer,
  teachersMarks: teachersMarksReduce,
  createPeriod: createPeriodSlice,
  studentsEDIT: studentsEditReduce,
  createCourse: createCourseReducer,
  getCourse: getCourseReducer,
  studentCourse: studentsCourseReducer,
  getAllGroups: getAllGroupsReducer,
  addMasterTeacher: addMasterTeacherReducer,
  getAllStudents: getAllStudentsReducer,
  getAllTeachers: getAllTeachersReducer,
  saveStudent: saveStudentReducer,
  patchStudent: patchStudentReducer,
  deleteStudent: deleteStudentReducer,
  saveTeacher: saveTeacherReducer,
  deleteTeacher: deleteTeacherReducer,
  createSchedule: createScheduleReducer,
  getFirstWeek: getFirstWeekReducer,
  getPeriods: getPeriodsReducer,
  createCGT: createCGTReducer,
  deleteSchedule: deleteScheduleReducer,
  deleteSubject: deleteSubjectReducer,
  createMark: createMarkReducer,
  createGroup: createGroupReducer,
  deleteMark: deleteMarkReducer,
  deleteGroup: deleteGroupReducer,
  getCGT: getCGTReducer,
  deleteCGT: deleteCGTReducer,
  getCredentials: getCredentialsReducer,
  patchMarks: patchMarksReducer,
  getTeachHomewk: getTeachHomewkReducer,
  patchTeachHomewk: patchTeachHomewkReducer,
  deletePeriod: deletePeriodReducer,
  patchCGT: patchCGTReducer,
  getAssessment: getAssessmentReducer,
  createAssessment: createAssessmentReducer,
  patchAssessment: patchAssessmentReducer,
  deleteAssessment: deleteAssessmentReducer,
  createQuarterlyGrade: createQuarterlyGradeReducer,
  getQuarterlyGrade: getQuarterlyGradeReducer,
  patchQuarterlyGrade: patchQuarterlyGradeReducer,
  deleteQuarterlyGrade: deleteQuarterlyGradeReducer,
  createParent: createParentReducer,
  patchParent: patchParentReducer,
  getParents: getParentsReducer,
  deleteParent: deleteParentReducer,
  createTrimAssessment: createTrimAssessmentReducer,
  getTrimAssessment: getTrimAssessmentReducer,
  patchTrimAssessment: patchTrimAssessmentReducer,
  deleteTrimAssessment: deleteTrimAssessmentReducer,
  patchLesson: patchLessonReducer,
  patchDRSchedule: patchDRScheduleReducer,
  patchCourse: patchCourseReducer,
  downloadExcelCredentials: downloadExcelCredentialsReducer,
  getSchoolsList: getSchoolsListReducer,
  deleteTable: deleteTableReducer,
  patchPeriod: patchPeriodReducer
});

export const store = configureStore({
  reducer: rootReducer,
});

// Типы для диспетчера и состояния
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
// Hook, который предоставляет типизированный доступ к диспетчеру
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
