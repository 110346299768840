import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Inter from "../../../interfaces/Interfaces";
import { appJSON } from "../../../utils/AxiosConfig";
import axios from "axios";

const initialState = {};

// DEPRECATED
export const createHomework = createAsyncThunk<
  void,
  Inter.ICreateHomework,
  { rejectValue: string }
>(
  "homework/createHomework",
  async (data: Inter.ICreateHomework, { rejectWithValue }) => {
    try {
      const URL = `${process.env.REACT_APP_CREATE_HOMEWORK}`;
      const response = await appJSON.patch<void>(URL, data);
      return response.data;
    } catch (error: unknown) {
      if (error instanceof axios.AxiosError) {
        return rejectWithValue(error.message);
      }
      throw error;
    }
  },
);

const createNewHomework = createSlice({
  name: "homework",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createHomework.fulfilled, (_, action) => {
      return action.payload;
    });
    builder.addCase(createHomework.rejected, (state) => {
      return state;
    });
  },
});

export default createNewHomework.reducer;
