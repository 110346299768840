import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Inter from "../../../interfaces/Interfaces";
import axios from "axios";
import { appJSON } from "../../../utils/AxiosConfig";

const initialState: Inter.getCGT = {
  cgt_list: [
    {
      group_name: "",
      teacher_name: "",
      trimester_id: 0,
      course_title: "",
      cgt_id: -1,
    },
  ],
  count: 1,
};

export const fetchGetCGT = createAsyncThunk<
  Inter.getCGT,
  Inter.sendCGT,
  { rejectValue: string }
>("cgt/getCGT", async (data: Inter.sendCGT, { rejectWithValue }) => {
  try {
    const URL = `${process.env.REACT_APP_GET_CGT}`;
    const response = await appJSON.get<Inter.getCGT>(URL, {
      params: {
        page_number: data.pageCount,
        elements_amount: data.elements_amount,
      },
    });
    return response.data;
  } catch (error: unknown) {
    if (error instanceof axios.AxiosError) {
      return rejectWithValue(error.message);
    }
    throw error;
  }
});

const getCGT = createSlice({
  name: "getCgt",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGetCGT.fulfilled, (state, action) => {
      return (state = action.payload);
    });
    builder.addCase(fetchGetCGT.rejected, (state) => {
      return state;
    });
  },
});

export default getCGT.reducer;
