import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Inter from "../../../interfaces/Interfaces";
import { appJSON } from "../../../utils/AxiosConfig";
import axios from "axios";

const initialState = {};

export const fetchPatchPeriod = createAsyncThunk<
  void,
  Inter.patchPeriod,
  { rejectValue: string }
>(
  "period/patchPeriod",
  async (data: Inter.patchPeriod, { rejectWithValue }) => {
    try {
      const URL = `${process.env.REACT_APP_PATCH_PERIOD}${data.id}`;
      const response = await appJSON.patch<void>(URL, data);
      return response.data;
    } catch (error: unknown) {
      if (error instanceof axios.AxiosError) {
        return rejectWithValue(error.message);
      }
      throw error;
    }
  },
);

const patchPeriod = createSlice({
  name: "periodPatch",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPatchPeriod.fulfilled, (_, action) => {
      return action.payload;
    });
    builder.addCase(fetchPatchPeriod.rejected, (state) => {
      return state;
    });
  },
});

export default patchPeriod.reducer;
