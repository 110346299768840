import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Inter from "../../../interfaces/Interfaces";
import { appJSON } from "../../../utils/AxiosConfig";
import axios from "axios";

const initialState = {};

export const fetchPatchTrimAssessment = createAsyncThunk<
  void,
  Inter.patchTrimAssessment,
  { rejectValue: string }
>(
  "trimAssessment/patchTrimAssessment",
  async (data: Inter.patchTrimAssessment, { rejectWithValue }) => {
    try {
      const role = localStorage.getItem("role")
      const URL = `${role === "director" ? 
        `${process.env.REACT_APP_PATCH_TRIM_ASSESSMENT}` : 
        `${process.env.REACT_APP_PATCH_TRIM_ASSESSMENT_TEACHER}`}/${data.id}`;
      const response = await appJSON.patch<void>(URL, data);
      return response.data;
    } catch (error: unknown) {
      if (error instanceof axios.AxiosError) {
        return rejectWithValue(error.message);
      }
      throw error;
    }
  },
);

const patchTrimAssessment = createSlice({
  name: "patchTrimAssessment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPatchTrimAssessment.fulfilled, (_, action) => {
      return action.payload;
    });
    builder.addCase(fetchPatchTrimAssessment.rejected, (state) => {
      return state;
    });
  },
});

export default patchTrimAssessment.reducer;
