import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as Inter from "../../../interfaces/Interfaces";

const initialState: Inter.getSchoolListArray = {
  schools: []
};

export const fetchGetSchoolsList = createAsyncThunk<
    Inter.getSchoolListArray,
    void,
    { rejectValue: string }
>("schools/getSchools", async (_, { rejectWithValue }) => {
    try {
        const URL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_SCHOOLS}`;
        const response = await axios.get<Inter.getSchoolListArray>(URL, {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        });

        return response.data;
    } catch (error: unknown) {
        if (error instanceof axios.AxiosError) {
            return rejectWithValue(error.message);
        }
        throw error;
    }
});

const getSchoolsList = createSlice({
    name: "get_schools",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchGetSchoolsList.fulfilled, (state, action) => {
            return action.payload;
        });
        builder.addCase(fetchGetSchoolsList.rejected, (state) => {
            return state;
        });
    },
});

export default getSchoolsList.reducer;
