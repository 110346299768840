import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Inter from "../../../interfaces/Interfaces";
import { appJSON } from "../../../utils/AxiosConfig";
import axios from "axios";

const initialState = {};

export const fetchDRSchedule = createAsyncThunk<
  void,
  Inter.patchSchedule,
  { rejectValue: string }
>(
  "DRSchedule/patchDRSchedule",
  async (data: Inter.patchSchedule, { rejectWithValue }) => {
    try {
      const role = localStorage.getItem("role")
      const URL = `${role === "director" ? 
          `${process.env.REACT_APP_PATCH_DR_SCHEDULE}` : 
          `${process.env.REACT_APP_SCHEDULE_UPDATE_TEACHER}`}/${data.id}`;
      const response = await appJSON.patch<void>(URL, data);
      return response.data;
    } catch (error: unknown) {
      if (error instanceof axios.AxiosError) {
        return rejectWithValue(error.message);
      }
      throw error;
    }
  },
);

const patchDRSchedule = createSlice({
  name: "patchDRSchedule",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDRSchedule.fulfilled, (_, action) => {
      return action.payload;
    });
    builder.addCase(fetchDRSchedule.rejected, (state) => {
      return state;
    });
  },
});

export default patchDRSchedule.reducer;
