import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Inter from "../../../interfaces/Interfaces";
import { appJSON } from "../../../utils/AxiosConfig";
import axios from "axios";

const initialState = {};

export const fetchDeleteSchedule = createAsyncThunk<
  void,
  Inter.deleteSchedule,
  { rejectValue: string }
>(
  "schedule/deleteSchedule",
  async (data: Inter.deleteSchedule, { rejectWithValue }) => {
    try {
      const URL = `${process.env.REACT_APP_DELETE_SCHEDULE}/${data.id}`;
      const response = await appJSON.delete<void>(URL);

      return response.data;
    } catch (error: unknown) {
      if (error instanceof axios.AxiosError) {
        return rejectWithValue(error.message);
      }
      throw error;
    }
  },
);

const deleteSchedule = createSlice({
  name: "scheduleDelete",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDeleteSchedule.fulfilled, (_, action) => {
      return action.payload;
    });
    builder.addCase(fetchDeleteSchedule.rejected, (state) => {
      return state;
    });
  },
});

export default deleteSchedule.reducer;
