import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { appJSON } from "../../../utils/AxiosConfig";
import axios from "axios";

const initialState = {};

export const fetchDeleteTrimAssessment = createAsyncThunk<
  void,
  string,
  { rejectValue: string }
>(
  "trimAssessment/deleteTrimAssessment",
  async (data: string, { rejectWithValue }) => {
    try {
      const role = localStorage.getItem("role")
      const URL = `${role === "director" ? 
        `${process.env.REACT_APP_DELETE_TRIM_ASSESSMENT}` :
        `${process.env.REACT_APP_DELETE_TRIM_ASSESSMENT_TEACHER}`}${data}`;
      const response = await appJSON.delete<void>(URL);
      return response.data;
    } catch (error: unknown) {
      if (error instanceof axios.AxiosError) {
        return rejectWithValue(error.message);
      }
      throw error;
    }
  },
);

const deleteTrimAssessment = createSlice({
  name: "deleteTrimAssessment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDeleteTrimAssessment.fulfilled, (_, action) => {
      return action.payload;
    });
    builder.addCase(fetchDeleteTrimAssessment.rejected, (state) => {
      return state;
    });
  },
});

export default deleteTrimAssessment.reducer;
