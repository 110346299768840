import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { appJSON } from "../../../utils/AxiosConfig";
import axios from "axios";

const initialState = {};

export const fetchDeleteAssessment = createAsyncThunk<
  void,
  string,
  { rejectValue: string }
>("assessment/deleteAssessment", async (data: string, { rejectWithValue }) => {
  try {
    const role = localStorage.getItem("role")
    const URL = `${role === "director" ? 
      `${process.env.REACT_APP_DELETE_ASSESSMENT}` : 
      `${process.env.REACT_APP_DELETE_ASSESSMENT}`}${data}`;
    const response = await appJSON.delete<void>(URL);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof axios.AxiosError) {
      return rejectWithValue(error.message);
    }
    throw error;
  }
});

const deleteAssessment = createSlice({
  name: "deleteAssessment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDeleteAssessment.fulfilled, (_, action) => {
      return action.payload;
    });
    builder.addCase(fetchDeleteAssessment.rejected, (state) => {
      return state;
    });
  },
});

export default deleteAssessment.reducer;
