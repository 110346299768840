import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Inter from "../../../interfaces/Interfaces";
import { appJSON } from "../../../utils/AxiosConfig";
import axios from "axios";

const initialState = {};

export const fetchCreateAssessment = createAsyncThunk<
  void,
  Inter.createAssessment,
  { rejectValue: string }
>(
  "assessment/createAssessment",
  async (data: Inter.createAssessment, { rejectWithValue }) => {
    try {
      const role = localStorage.getItem("role")
      const URL = role === "director" ? 
        `${process.env.REACT_APP_CREATE_ASSESSMENT}` : 
        `${process.env.REACT_APP_CREATE_ASSESSMENT_TEACHER}`;
      const response = await appJSON.post<void>(URL, data);

      return response.data;
    } catch (error: unknown) {
      if (error instanceof axios.AxiosError) {
        return rejectWithValue(error.message);
      }
      throw error;
    }
  },
);

const createAssessment = createSlice({
  name: "create_assessment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCreateAssessment.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(fetchCreateAssessment.rejected, (state) => {
      return state;
    });
  },
});

export default createAssessment.reducer;
