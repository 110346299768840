import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { appJSON } from "../../../utils/AxiosConfig";
import axios from "axios";

const initialState = {};

export const fetchDeleteSubject = createAsyncThunk<
  void,
  string,
  { rejectValue: string }
>("subject/deleteSubject", async (data: string, { rejectWithValue }) => {
  try {
    const encodedData = encodeURIComponent(data);
    const URL = `${process.env.REACT_APP_DELETE_SUBJECT}/${encodedData}`;
    const response = await appJSON.delete<void>(URL);
    if (response.status === 200) {
      alert("Удачно!");
    }
    return response.data;
  } catch (error: unknown) {
    if (error instanceof axios.AxiosError) {
      return rejectWithValue(error.message);
    }
    throw error;
  }
});

const deleteSubject = createSlice({
  name: "subjectDelete",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDeleteSubject.fulfilled, (_, action) => {
      return action.payload;
    });
    builder.addCase(fetchDeleteSubject.rejected, (state) => {
      return state;
    });
  },
});

export default deleteSubject.reducer;
