import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Inter from "../../../interfaces/Interfaces";
import app from "../../../utils/AxiosConfig";
import axios from "axios";

const initialState: Inter.getPeriods[] = [
  {
    date_start: "",
    date_finish: "",
    semester: -1,
    id: -1,
    description: ""
  },
];

export const fetchGetPeriods = createAsyncThunk<
  Inter.getPeriods[],
  void,
  { rejectValue: string }
>("periods/getPeriods", async (_, { rejectWithValue }) => {
  try {
    const role = localStorage.getItem("role")
    const URL = role === "director" ? 
        `${process.env.REACT_APP_GET_PERIODS}` : 
        `${process.env.REACT_APP_TRIMESTERS_INFO}`;
    const response = await app.get<Inter.getPeriods[]>(URL);

    return response.data;
  } catch (error: unknown) {
    if (error instanceof axios.AxiosError) {
      return rejectWithValue(error.message);
    }
    throw error;
  }
});

const getPeriodsSlice = createSlice({
  name: "get_periods",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGetPeriods.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(fetchGetPeriods.rejected, (state) => {
      return state;
    });
  },
});

export default getPeriodsSlice.reducer;
