import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Inter from "../../../interfaces/Interfaces";
import axios from "axios";
import { appJSON } from "../../../utils/AxiosConfig";

const initialState: Inter.getAllTeachersWithCount = {
  teachers: [
    {
      first_name: "",
      last_name: "",
      birthdate: "",
      is_male: true,
      phone_number: "",
      address: "",
      unique_id: "",
      id: 0,
      master_group_id: 0,
    },
  ],
  count: 0,
};

export const fetchAllTeachersNoGroup = createAsyncThunk<
  Inter.getAllTeachersWithCount,
  Inter.getTeachersSettings,
  { rejectValue: string }
>(
  "teachers/getAllTeachers",
  async (data: Inter.getTeachersSettings, { rejectWithValue }) => {
    const params = {
      ...(data.page_number && { page_number: data.page_number }),
      ...(data.elements_amount && { elements_amount: data.elements_amount }),
      ...(data.name_filter && { name_filter: data.name_filter }),
      ...(data.surname_filter && { surname_filter: data.surname_filter }),
    };

    try {
      const URL = `${process.env.REACT_APP_GET_ALL_TEACHERS}`;
      const response = await appJSON.get<Inter.getAllTeachersWithCount>(URL, {
        params: params,
      });
      return response.data;
    } catch (error: unknown) {
      if (error instanceof axios.AxiosError) {
        return rejectWithValue(error.message);
      }
      throw error;
    }
  },
);

const getAllTeacher = createSlice({
  name: "teachers_group",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllTeachersNoGroup.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(fetchAllTeachersNoGroup.rejected, (state) => {
      return state;
    });
  },
});

export default getAllTeacher.reducer;
