import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Inter from "../../../interfaces/Interfaces";
import app from "../../../utils/AxiosConfig";
import axios from "axios";

const initialState: Inter.getTeachHomework = {
  date: "",
  homework: null,
  description: null,
  room: null,
  theme: null,
  id: 0,
};

export const fetchTeacherHomework = createAsyncThunk<
  Inter.getTeachHomework,
  number,
  { rejectValue: string }
>("homework/getTeacherHomework", async (data: number, { rejectWithValue }) => {
  try {
    // eslint-disable-next-line no-undef
    const URL = `${process.env.REACT_APP_GET_TEACHER_HOMEWORK}${data}`;
    const response = await app.get<Inter.getTeachHomework>(URL);

    return response.data;
  } catch (error: unknown) {
    if (error instanceof axios.AxiosError) {
      return rejectWithValue(error.message);
    }
    throw error;
  }
});

const getTeacherHomeworkSlice = createSlice({
  name: "homeworkTeach",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTeacherHomework.fulfilled, (state, action) => {
      return (state = action.payload);
    });
    builder.addCase(fetchTeacherHomework.rejected, (state) => {
      return state;
    });
  },
});

export default getTeacherHomeworkSlice.reducer;
