import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Inter from "../../../interfaces/Interfaces";
import { appJSON } from "../../../utils/AxiosConfig";
import axios from "axios";

const initialState: Inter.marksDetails = {
  group: "",
  subject: "",
  data: "",
  student: "",
};

export const fetchCreateMark = createAsyncThunk<
  void,
  Inter.createMark,
  { rejectValue: string }
>("mark/createMark", async (data: Inter.createMark, { rejectWithValue }) => {
  try {
    const URL = `${process.env.REACT_APP_CREATE_MARK}`;
    const response = await appJSON.post<void>(URL, data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof axios.AxiosError) {
      return rejectWithValue(error.message);
    }
    throw error;
  }
});

const createNewMark = createSlice({
  name: "mark_create",
  initialState,
  reducers: {
    setMarksDetails: (state, action) => {
      state.group = action.payload.group;
      state.subject = action.payload.subject;
      state.data = action.payload.data;
      state.student = action.payload.student;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCreateMark.fulfilled, (state) => {
      return state;
    });
    builder.addCase(fetchCreateMark.rejected, (state) => {
      return state;
    });
  },
});

export const { setMarksDetails } = createNewMark.actions;
export default createNewMark.reducer;
